import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import API_BASE_URL from "../../config";

function CertificateForm() {
    const {id} = useParams();

    const [certificate, setCertificate] = useState({
        name: '',
        certificate_img: null,
        thumbnail_img: null
    });

    useEffect(() => {
        if (id) {
            fetchCertificate();
        }
    }, [id]);

    const navigate = useNavigate();

    const fetchCertificate = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/certificate/view_one.php?id=${id}`);
            const productData = response.data;
            if (productData.certificate_img) {
                productData.certificate_img_url = `${API_BASE_URL}/certificate/${productData.certificate_img}`;
            }
            if (productData.thumbnail_img) {
                productData.thumbnail_img_url = `${API_BASE_URL}/certificate/${productData.thumbnail_img}`;
            }
            setCertificate(response.data);
        } catch (error) {
            console.error('Error fetching certificate:', error);
        }
    };
    const {getRootProps: getCertificateRootProps, getInputProps: getCertificateInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                setCertificate({
                    ...certificate,
                    certificate_img: acceptedFiles[0],
                    certificate_img_url: URL.createObjectURL(acceptedFiles[0]) // For previewing the image
                });
            }
        }
    });

    const {getRootProps: getThumbnailRootProps, getInputProps: getThumbnailInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                setCertificate({
                    ...certificate,
                    thumbnail_img: acceptedFiles[0],
                    thumbnail_img_url: URL.createObjectURL(acceptedFiles[0]) // For previewing the image
                });
            }
        }
    });

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setCertificate({
            ...certificate,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        // Append all fields to FormData
        for (let key in certificate) {
            formData.append(key, certificate[key]);
        }

        try {
            const url = id ? `${API_BASE_URL}/certificate/update.php?id=${id}` : `${API_BASE_URL}/certificate/create.php`;
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // Important for file upload
                }
            });
            navigate('/certificates');
        } catch (error) {
            console.error('Error saving certificate:', error);
        }
    };

    return (
        <div>
            <h2>Create Certificate</h2>
            <div className="card">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="form-label">Name</label>
                            <input
                                placeholder="Certificate Name"
                                className="form-control"
                                type="text"
                                name="name"
                                value={certificate.name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="form-label">Certificate Image</label>
                            <div {...getCertificateRootProps({className: 'dropzone'})}>
                                <input {...getCertificateInputProps()} />
                                <p>Drag 'n' drop a certificate image here, or click to select</p>
                            </div>
                            {certificate.certificate_img_url && (
                                <div>
                                    <img
                                        src={certificate.certificate_img_url}
                                        alt="Certificate Preview"
                                        style={{maxWidth: '100%', maxHeight: '200px', marginTop: '10px'}}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="mb-4">
                            <label className="form-label">Thumbnail Image</label>
                            <div {...getThumbnailRootProps({className: 'dropzone'})}>
                                <input {...getThumbnailInputProps()} />
                                <p>Drag 'n' drop a thumbnail image here, or click to select</p>
                            </div>
                            {certificate.thumbnail_img_url && (
                                <div>
                                    <img
                                        src={certificate.thumbnail_img_url}
                                        alt="Thumbnail Preview"
                                        style={{maxWidth: '100%', maxHeight: '200px', marginTop: '10px'}}
                                    />
                                </div>
                            )}
                        </div>
                        <button className="btn btn-primary" type="submit">{id ? 'Update' : 'Create'}</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CertificateForm;
