import React from 'react';
import Sidebar from "./sidebar";
import classNames from "classnames";

const Layout = ({isMiniSidebar, isShowSidebar, toggleSidebar, logoutUser, children}) => {
    const sidebarClasses = classNames({
        'mini-sidebar page-wrapper': isMiniSidebar,
        'show-sidebar': isShowSidebar,
    });

    return (
        <div
            id="main-wrapper"
            data-layout="vertical"
            data-navbarbg="skin6"
            data-sidebar-position="fixed"
            data-header-position="fixed"
            className={sidebarClasses}
            data-sidebartype={isMiniSidebar ? 'mini-sidebar' : 'full'}
        >
            <Sidebar toggleSidebar={toggleSidebar}></Sidebar>

            <div className="body-wrapper">
                <header className="app-header">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <ul className="navbar-nav">
                            <li className="nav-item d-block d-xl-none">
                                <a
                                    className="nav-link sidebartoggler nav-icon-hover"
                                    id="headerCollapse"
                                    onClick={toggleSidebar}
                                >
                                    <i className="ti ti-menu-2"></i>
                                </a>
                            </li>
                        </ul>
                        <div className="navbar-collapse justify-content-end px-0" id="navbarNav">
                            <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                                <a href="https://wesfoeco.com/" target="_blank" className="btn btn-primary">
                                    Go to Website
                                </a>
                                <button className="btn btn-outline-primary mx-3" onClick={logoutUser}>
                                    Logout
                                </button>
                            </ul>
                        </div>
                    </nav>
                </header>
                <div className="container-fluid">
                    {children}
                </div>
            </div>
        </div>
    )
};

export default Layout;
