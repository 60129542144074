import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_BASE_URL from "../../config";

function Products() {
    const [products, setProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // State to hold the search query
    const [message, setMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const productsPerPage = 6; // Number of products per page

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/product/view.php`);
            if (response.data.message) {
                setMessage(response.data.message);
            } else {
                setProducts(response.data);
            }
        } catch (error) {
            console.error('Error fetching products:', error);
            setMessage('Error fetching products');
        }
    };

    const handleDelete = async (productId) => {
        if (window.confirm("Are you sure you want to delete this product?")) {
            try {
                const response = await axios.get(`${API_BASE_URL}/product/delete.php?id=${productId}`);
                if (response.data.message === 'Product deleted successfully.') {
                    setMessage(response.data.message);
                    toast.success('Product deleted successfully.');

                    // Update the products state to remove the deleted product
                    setProducts(products.filter(product => product.p_id !== productId));
                } else {
                    setMessage(response.data.message);
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error deleting product:', error);
                setMessage('Error deleting product');
                toast.error('Error deleting product');
            }
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset to the first page on search
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Filter products based on search query
    const filteredProducts = products.filter(product =>
        product.p_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.p_category.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Calculate the products to be displayed on the current page
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    // Calculate total pages
    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    return (
        <>
            <ToastContainer/>

            <div className="mb-4">
                <Link to="/product/create" className="btn btn-primary">Add Product</Link>
            </div>

            <div className="mb-4">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search by name or category"
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </div>

            <div className="card">
                <div className="card-body">
                    <div>
                        {/*<h3>{message}</h3>*/}
                    </div>
                    <div className="row">
                        {currentProducts.map((product) => (
                            <div className="col-md-6 col-xl-4" key={product.p_id}>
                                <div className="card product__card">
                                    <div className="card-header">
                                        <h5 className="fw-semibold mb-0 py-2 product__card-desc">{product.p_name}</h5>
                                    </div>
                                    <div className="card-img-top">
                                        <img
                                            src={`${API_BASE_URL}/product/${product.p_image}`}
                                            className="img-fluid" alt={product.p_name}/>
                                    </div>
                                    <div className="card-body">
                                        <div
                                            className="d-flex mb-2 fs-6 text-primary fw-bold justify-content-between">
                                            <span>Category</span>
                                            <span className="text-capitalize">
                                            {product.p_category}
                                            </span>
                                        </div>

                                        <div className="py-2 border-bottom">
                                            <div className="d-flex fs-4">Diameter : {product.p_diameter}mm</div>
                                        </div>
                                        <div className="d-flex justify-content-between py-2 border-bottom">
                                            <div className="fs-4">Length : {product.p_length}mm</div>
                                            <div className="fs-4">Width : {product.p_width}mm</div>
                                        </div>
                                        <div className="d-flex justify-content-between  py-2 border-bottom">
                                            <div className="fs-4">Depth : {product.p_depth}mm</div>
                                            <div className="fs-4">Volume : {product.p_volume}ml</div>
                                        </div>

                                        <div className="d-flex justify-content-between py-2 border-bottom">
                                            <div className="d-flex fs-4">Hot
                                                Product: {product.p_hot_product ? 'Yes' : 'No'}</div>
                                            <div className="d-flex fs-4">New
                                                Arrival: {product.p_new_arrival ? 'Yes' : 'No'}</div>
                                        </div>
                                        <div className="d-flex flex-column mt-3">
                                            <Link to={`/product/edit/${product.p_id}`}
                                                  className="btn btn-primary mb-2">Edit</Link>
                                            <button className="btn btn-outline-secondary"
                                                    onClick={() => handleDelete(product.p_id)}>Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                    {Array.from({length: totalPages}, (_, i) => (
                        <li key={i + 1} className={`page-item ${i + 1 === currentPage ? 'active' : ''}`}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className="page-link" onClick={() => handlePageChange(i + 1)}>
                                {i + 1}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        </>
    );
}

export default Products;
