import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import DashboardComponent from './pages/dashboard';
import CompanyComponent from './pages/Company/company';
import Login from './components/login';
import MainLayout from '../src/components/layout';
import Products from "./pages/Product/products";
import ProductForm from "./pages/Product/productForm";
import Category from "./pages/Category/category";
import CategoryForm from "./pages/Category/categoryForm";
import Certificates from "./pages/Certificates/certificates";
import CertificateForm from "./pages/Certificates/certificateForm";

const App = () => {
    const [isMiniSidebar, setIsMiniSidebar] = useState(false);
    const [isShowSidebar, setIsShowSidebar] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        setIsAuthenticated(!!token);
    }, []);

    useEffect(() => {
        setSidebarType();
        window.addEventListener('resize', setSidebarType);

        return () => {
            window.removeEventListener('resize', setSidebarType);
        };
    }, []);

    const setSidebarType = () => {
        const width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
        setIsMiniSidebar(width < 1199);
    };

    const toggleSidebar = () => {
        setIsMiniSidebar(!isMiniSidebar);
        setIsShowSidebar(!isShowSidebar);
    };

    const logoutUser = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
    };

    return (<Router>
        <Routes>
            <Route path="/login"
                   element={isAuthenticated ? <Navigate to="/"/> : <Login setIsAuthenticated={setIsAuthenticated}/>}/>
            <Route
                path="*"
                element={isAuthenticated ? (<MainLayout
                    isMiniSidebar={isMiniSidebar}
                    isShowSidebar={isShowSidebar}
                    toggleSidebar={toggleSidebar}
                    logoutUser={logoutUser}
                >
                    <Routes>
                        <Route path="/" element={<DashboardComponent/>}/>
                        <Route path="/company" element={<CompanyComponent/>}/>
                        <Route path="/products" element={<Products/>}/>
                        <Route path="/product/create" element={<ProductForm/>}/>
                        <Route path="/product/edit/:id" element={<ProductForm/>}/>
                        <Route path="/categories" element={<Category/>}/>
                        <Route path="/category/create" element={<CategoryForm/>}/>
                        <Route path="/category/edit/:id" element={<CategoryForm/>}/>
                        <Route path="/certificates" element={<Certificates/>}/>
                        <Route path="/certificate/create" element={<CertificateForm/>}/>
                        <Route path="/certificate/edit/:id" element={<CertificateForm/>}/>
                    </Routes>
                </MainLayout>) : (<Navigate to="/login"/>)}
            />
        </Routes>
    </Router>);
};

export default App;
