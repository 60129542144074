import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import API_BASE_URL from "../../config";

function ProductForm() {
    const {id} = useParams();
    const [product, setProduct] = useState({
        p_name: '',
        p_diameter: '',
        p_description: '',
        p_length: '',
        p_width: '',
        p_depth: '',
        p_volume: '',
        p_category: '',
        p_image: null, // Use null for file object
        p_hot_product: false,
        p_new_arrival: false
    });
    const [categories, setCategories] = useState([]); // State to hold categories
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            fetchProduct();
        }
        fetchCategories(); // Fetch categories when component mounts
    }, [id]);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/category/view.php`);
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };
    const {getRootProps, getInputProps, acceptedFiles} = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                setProduct({
                    ...product,
                    p_image: acceptedFiles[0],
                    p_image_url: URL.createObjectURL(acceptedFiles[0]) // For previewing the image
                });
            }
        }
    });


    const fetchProduct = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/product/view_one.php?id=${id}`);
            const productData = response.data;
            if (productData.p_image) {
                productData.p_image_url = `${API_BASE_URL}/product/${productData.p_image}`;
            }
            setProduct(response.data);
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setProduct({...product, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        // Append all fields to FormData
        for (let key in product) {
            formData.append(key, product[key]);
        }

        try {
            const url = id ? `${API_BASE_URL}/product/update.php?id=${id}` : `${API_BASE_URL}/product/create.php`;
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // Important for file upload
                }
            });
            navigate('/products');
        } catch (error) {
            console.error('Error saving product:', error);
        }
    };

    return (<div>
        <h2>{id ? 'Edit' : 'Create'} Product</h2>
        <div className="card">
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="form-label">Name</label>
                        <input placeholder="Product Name" className="form-control" type="text" name="p_name"
                               value={product.p_name || ''}
                               onChange={handleChange} required/>
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Description</label>
                        <input placeholder="Description" className="form-control" type="text" name="p_description"
                               value={product.p_description} onChange={handleChange}/>
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Diameter</label>
                        <input placeholder="Diameter" className="form-control" type="text" name="p_diameter"
                               value={product.p_diameter || ''}
                               onChange={handleChange}/>
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Length</label>
                        <input placeholder="Length" className="form-control" type="text" name="p_length"
                               value={product.p_length || ''}
                               onChange={handleChange}/>
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Width</label>
                        <input placeholder="Width" className="form-control" type="text" name="p_width"
                               value={product.p_width || ''}
                               onChange={handleChange}/>
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Depth</label>
                        <input placeholder="Depth" className="form-control" type="text" name="p_depth"
                               value={product.p_depth || ''}
                               onChange={handleChange}/>
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Volume</label>
                        <input placeholder="Volume" className="form-control" type="text" name="p_volume"
                               value={product.p_volume || ''}
                               onChange={handleChange}/>
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Category:</label>
                        <select
                            className="form-control"
                            name="p_category"
                            value={product.p_category}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>Select a category</option>
                            {categories.map((category) => (
                                <option key={category.category} value={category.category}>
                                    {category.category}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="form-label">Image:</label>
                        <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        </div>
                        {product.p_image_url && (
                            <div>
                                <img src={product.p_image_url} alt="Preview"
                                     style={{width: "auto", maxHeight: '100px', marginTop: '10px'}}/>
                            </div>
                        )}
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Hot Product:</label>
                        <select className="form-control" name="p_hot_product"
                                value={product.p_hot_product ? 'true' : 'false'}
                                onChange={handleChange}>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="form-label">New Arrival:</label>
                        <select className="form-control" name="p_new_arrival"
                                value={product.p_new_arrival ? 'true' : 'false'}
                                onChange={handleChange}>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <button className="btn btn-primary" type="submit">{id ? 'Update' : 'Create'}</button>
                </form>
            </div>
        </div>
    </div>);
}

export default ProductForm;

