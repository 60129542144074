import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import logoUrl from "../assets/images/logo.png"

function Sidebar({toggleSidebar}) {

    return (<>
        <aside className="left-sidebar">
            <div>
                <div className="brand-logo d-flex align-items-center justify-content-between">
                    <a className="text-nowrap logo-img">
                        <img src={logoUrl} width="180" alt=""/>
                    </a>
                    <div className="close-btn d-xl-none d-flex sidebartoggler cursor-pointer" id="sidebarCollapse"
                         onClick={toggleSidebar}>
                        <i className="ti ti-square-x fs-8"></i>
                    </div>
                </div>
                <nav className="sidebar-nav scroll-sidebar" data-simplebar="">
                    <ul id="sidebarnav">


                        <li className="nav-small-cap">
                            <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                            <span className="hide-menu">Home</span>
                        </li>
                        <li className="sidebar-item">
                            <Link to="/" className="sidebar-link" aria-expanded="false">
                                <span>
                                  <i className="ti ti-layout-dashboard"></i>
                                </span>
                                <span className="hide-menu">Dashboard</span>
                            </Link>
                        </li>

                        <li className="nav-small-cap">
                            <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                            <span className="hide-menu">Company</span>
                        </li>
                        <li className="sidebar-item">
                            <Link to="/company" className="sidebar-link" aria-expanded="false">
                            <span>
                              <i className="ti ti-building-factory-2"></i>
                            </span>
                                <span className="hide-menu">Company Info</span>
                            </Link>
                        </li>


                        <li className="nav-small-cap">
                            <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                            <span className="hide-menu">Products</span>
                        </li>
                        <li className="sidebar-item">
                            <Link to="/products" className="sidebar-link" aria-expanded="false">
                            <span>
                              <i className="ti ti-cards"></i>
                            </span>
                                <span className="hide-menu">Products</span>
                            </Link>
                        </li>
                        <li className="nav-small-cap">
                            <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                            <span className="hide-menu">Category</span>
                        </li>
                        <li className="sidebar-item">
                            <Link to="/categories" className="sidebar-link" aria-expanded="false">
                            <span>
                             <i className="ti ti-category-2"></i>
                            </span>
                                <span className="hide-menu">Category</span>
                            </Link>
                        </li>
                        <li className="nav-small-cap">
                            <i className="ti ti-file nav-small-cap-icon fs-4"></i>
                            <span className="hide-menu">Certificates</span>
                        </li>
                        <li className="sidebar-item">
                            <Link to="/certificates" className="sidebar-link" aria-expanded="false">
                            <span>
                             <i className="ti ti-file"></i>
                            </span>
                                <span className="hide-menu">Certificates</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    </>);
}

export default Sidebar;
