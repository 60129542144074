import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import API_BASE_URL from "../../config";

function CategoryForm() {
    const {id} = useParams();
    const [category, setCategory] = useState({
        category: '',
        image: null,
    });
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            fetchCategory();
        }
    }, [id]);

    const {getRootProps, getInputProps, acceptedFiles} = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                setCategory({
                    ...category,
                    image: acceptedFiles[0],
                    image_url: URL.createObjectURL(acceptedFiles[0]) // For previewing the image
                });
            }
        }
    });


    const fetchCategory = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/category/view_one.php?id=${id}`);
            const categoryData = response.data;
            if (categoryData.image) {
                categoryData.image_url = `${API_BASE_URL}/category/${categoryData.image}`;
            }
            setCategory(response.data);
        } catch (error) {
            console.error('Error fetching product:', error);
            setMessage('Error fetching product');
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setCategory({...category, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        // Append all fields to FormData
        for (let key in category) {
            formData.append(key, category[key]);
        }

        try {
            const url = id ? `${API_BASE_URL}/category/update.php?id=${id}` : `${API_BASE_URL}/category/create.php`;
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // Important for file upload
                }
            });
            setMessage(response.data.message);
            navigate('/categories');
        } catch (error) {
            console.error('Error saving category:', error);
            setMessage('Error saving category');
        }
    };

    return (<div>
        <h2>{id ? 'Edit' : 'Create'} Category</h2>
        <div className="card">
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="form-label">Category :</label>
                        <input className="form-control" placeholder="Category" type="text" name="category"
                               value={category.category}
                               onChange={handleChange} required/>
                    </div>

                    <div className="mb-4">
                        <label className="form-label">Image:</label>
                        <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        </div>
                        {category.image_url && (
                            <div>
                                <img src={category.image_url} alt="Preview"
                                     style={{width: "auto", maxHeight: '100px', marginTop: '10px'}}/>
                            </div>
                        )}
                    </div>
                    <button className="btn btn-primary" type="submit">{id ? 'Update' : 'Create'}</button>
                </form>
            </div>
        </div>
        <div>{message}</div>
    </div>);
}

export default CategoryForm;

