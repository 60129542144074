import React, {useState} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import logoUrl from "../assets/images/logo.png"
import API_BASE_URL from "../config";

function Login({setIsAuthenticated}) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}/login.php`, {
                username, password
            });
            console.log(response); // Debug: Log API response
            if (response.data.token) {
                localStorage.setItem('token', response.data.token);
                setMessage('Login successful!');
                setIsAuthenticated(true); // Update authentication state
                navigate('/');
            } else {
                setMessage('Login failed. Please check your username and password.');
            }
        } catch (error) {
            console.error(error); // Debug: Log any error
            setMessage('Login failed. Please check your username and password.');
        }
    };


    return (<>
        <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6"
             data-sidebartype="full"
             data-sidebar-position="fixed" data-header-position="fixed">
            <div
                className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center w-100">
                    <div className="row justify-content-center w-100">
                        <div className="col-md-8 col-lg-6 col-xxl-3">
                            <div className="card mb-0">
                                <div className="card-body">
                                    <a href="https://wesfoeco.com/" target="_blank"
                                       className="text-nowrap logo-img text-center mx-auto d-block py-3 w-100">
                                        <img src={logoUrl} width="180" alt=""/>
                                    </a>
                                    <form onSubmit={handleLogin}>
                                        <div className="mb-3">
                                            <label className="form-label">Username</label>
                                            <input placeholder="Username / Email Id" type="text" className="form-control" value={username}
                                                   onChange={(e) => setUsername(e.target.value)}/>
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label">Password</label>
                                            <input placeholder="Password" type="password" className="form-control" id="exampleInputPassword1"
                                                   value={password} onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>

                                        <button className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2">Sign
                                            In
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>)
}

export default Login;