import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_BASE_URL from "../../config";

function Category() {
    const [categories, setCategories] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/category/view.php`);
            console.log(response); // Log response
            if (response.data.message) {
                setMessage(response.data.message);
            } else {
                setCategories(response.data);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
            setMessage('Error fetching categories');
        }
    };

    const handleDeleteCategory = async (categoryId) => {
        if (window.confirm("Are you sure you want to delete this category?")) {
            try {
                const response = await axios.get(`${API_BASE_URL}/category/delete.php?id=${categoryId}`);
                if (response.data.message === 'Category deleted successfully.') {
                    setMessage(response.data.message);
                    toast.success('Category deleted successfully.');

                    // Update the categories state to remove the deleted category
                    setCategories(categories.filter(category => category.id !== categoryId));
                } else {
                    setMessage(response.data.message);
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error deleting category:', error);
                setMessage('Error deleting category');
                toast.error('Error deleting category');
            }
        }
    };

    return (<>
        <ToastContainer/>

        <div className="mb-4">
            <Link to="/category/create" className="btn btn-primary">Add Category</Link>
        </div>

        <div className="card">
            <div className="card-body">
                <div>
                    {/*<h3>{message}</h3>*/}
                </div>
                <div className="row">
                    {categories.map((category) => (<div className="col-md-6 col-xl-4" key={category.id}>
                        <div className="card product__card">
                            <div className="card-img-top">
                                <img
                                    src={`${API_BASE_URL}/category/${category.image}`}
                                    className="img-fluid" alt={category.category}/>
                            </div>
                            <div className="card-body">
                                <h5 className="fw-semibold mb-0 py-2 fs-8 product__card-desc text-primary text-center text-capitalize">{category.category}</h5>
                                <div className="d-flex flex-column mt-3">
                                    <Link to={`/category/edit/${category.id}`}
                                          className="btn btn-primary mb-2">Edit</Link>
                                    <button className="btn btn-outline-secondary"
                                            onClick={() => handleDeleteCategory(category.id)}>Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>))}
                </div>
            </div>
        </div>
    </>);
}

export default Category;
