import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_BASE_URL from "../../config";

function Certificates() {
    const [certificates, setCertificates] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchCertificates();
    }, []);

    const fetchCertificates = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/certificate/view.php`);
            if (response.data.message) {
                setMessage(response.data.message);
            } else {
                setCertificates(response.data);
            }
        } catch (error) {
            console.error('Error fetching certificates:', error);
            setMessage('Error fetching certificates');
        }
    };

    const handleDelete = async (certificateId) => {
        if (window.confirm("Are you sure you want to delete this certificate?")) {
            try {
                const response = await axios.get(`${API_BASE_URL}/certificate/delete.php?id=${certificateId}`);
                if (response.data.message === 'Certificate deleted successfully.') {
                    setMessage(response.data.message);
                    toast.success('Certificate deleted successfully.');

                    setCertificates(certificates.filter(cert => cert.id !== certificateId));
                } else {
                    setMessage(response.data.message);
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error deleting certificate:', error);
                setMessage('Error deleting certificate');
                toast.error('Error deleting certificate');
            }
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredCertificates = certificates.filter(cert =>
            cert.name.toLowerCase().includes(searchQuery.toLowerCase())
        // Add more fields as per your certificate API response structure
    );

    return (
        <>
            <ToastContainer/>

            <div className="mb-4">
                <Link to="/certificate/create" className="btn btn-primary">Add Certificate</Link>
            </div>

            <div className="mb-4">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search by name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </div>

            <div className="card">
                <div className="card-body">
                    <div>
                        {/* <h3>{message}</h3> */}
                    </div>
                    <div className="row">
                        {filteredCertificates.map((certificate) => (
                            <div className="col-md-6 col-xl-4" key={certificate.id}>
                                <div className="card certificate__card"> {/* Adjust class name */}
                                    <div className="card-header">
                                        <h5 className="fw-semibold mb-0 py-2 certificate__card-desc">{certificate.name}</h5>
                                    </div>
                                    <div className="card-img-top">
                                        <img
                                            src={`${API_BASE_URL}/certificate/${certificate.certificate_img}`}
                                            className="img-fluid" alt={certificate.name}/>
                                    </div>
                                    <div className="card-body">
                                        <h6 className="fw-semibold mb-0 py-2">Thumbnail Image</h6>
                                        <div className="d-flex mb-2 fs-6 text-primary fw-bold justify-content-center">
                                            <img src={`${API_BASE_URL}/certificate/${certificate.thumbnail_img}`}
                                                 className="img-fluid" alt={certificate.thumbnail_img}/>
                                        </div>

                                        {/* Add other fields specific to certificates */}

                                        <div className="d-flex flex-column mt-3">
                                            <Link to={`/certificate/edit/${certificate.id}`}
                                                  className="btn btn-primary mb-2">Edit</Link> {/* Adjust attribute name */}
                                            <button className="btn btn-outline-secondary"
                                                    onClick={() => handleDelete(certificate.id)}>Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Certificates;
