import React, {useState, useEffect} from "react";
import API_BASE_URL from "../../config";

function Company() {
    const [companyData, setCompanyData] = useState({
        address: "",
        map_link: "",
        mobile_no: "",
        email: "",
        facebook: "",
        instagram: "",
        youtube: "",
    });

    const [logo, setLogo] = useState(null); // State to hold the selected logo file

    useEffect(() => {
        const fetchCompanyData = async () => {
            const response = await fetch(`${API_BASE_URL}/company.php`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (!response.ok) {
                throw new Error("Failed to fetch Company data");
            }

            const data = await response.json();
            setCompanyData(data);
        };

        fetchCompanyData();
    }, []);


    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("address", companyData.address);
        formData.append("map_link", companyData.map_link);
        formData.append("mobile_no", companyData.mobile_no);
        formData.append("email", companyData.email);
        formData.append("facebook", companyData.facebook);
        formData.append("instagram", companyData.instagram);
        formData.append("youtube", companyData.youtube);
        formData.append("linkedin", companyData.linkedin);

        try {
            const response = await fetch(`${API_BASE_URL}/company.php`, {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Failed to save Company details");
            }

            const result = await response.json();
            alert(result.message); // Show success message
        } catch (error) {
            console.error("Error saving Company details:", error);
            alert("Failed to save Company details");
        }
    };

    const handleLogoChange = (event) => {
        setLogo(event.target.files[0]);
    };

    return (
        <>
            <h1 className="mb-4">Company Details</h1>

            <div className="card">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="form-label">Address:</label>
                            <input
                                type="text"
                                className="form-control"
                                value={companyData.address}
                                onChange={(e) =>
                                    setCompanyData({...companyData, address: e.target.value})
                                }
                            />
                        </div>
                        <div className="mb-4">
                            <label className="form-label">Map Link:</label>
                            <input
                                type="text"
                                className="form-control"
                                value={companyData.map_link}
                                onChange={(e) =>
                                    setCompanyData({...companyData, map_link: e.target.value})
                                }
                            />
                        </div>
                        <div className="mb-4">
                            <label className="form-label">Mobile No:</label>
                            <input
                                type="number"
                                className="form-control"
                                value={companyData.mobile_no}
                                onChange={(e) =>
                                    setCompanyData({...companyData, mobile_no: e.target.value})
                                }
                            />
                        </div>
                        <div className="mb-4">
                            <label className="form-label">Email:</label>
                            <input
                                type="email"
                                className="form-control"
                                value={companyData.email}
                                onChange={(e) =>
                                    setCompanyData({...companyData, email: e.target.value})
                                }
                            />
                        </div>
                        <div className="mb-4">
                            <label className="form-label">Facebook:</label>
                            <input
                                type="text"
                                className="form-control"
                                value={companyData.facebook}
                                onChange={(e) =>
                                    setCompanyData({...companyData, facebook: e.target.value})
                                }
                            />
                        </div>
                        <div className="mb-4">
                            <label className="form-label">Instagram:</label>
                            <input
                                type="text"
                                className="form-control"
                                value={companyData.instagram}
                                onChange={(e) =>
                                    setCompanyData({...companyData, instagram: e.target.value})
                                }
                            />
                        </div>
                        <div className="mb-4">
                            <label className="form-label">YouTube:</label>
                            <input
                                type="text"
                                className="form-control"
                                value={companyData.youtube}
                                onChange={(e) =>
                                    setCompanyData({...companyData, youtube: e.target.value})
                                }
                            />
                        </div>
                        <div className="mb-4">
                            <label className="form-label">Linkedin :</label>
                            <input
                                type="text"
                                className="form-control"
                                value={companyData.linkedin}
                                onChange={(e) =>
                                    setCompanyData({...companyData, linkedin: e.target.value})
                                }
                            />
                        </div>
                        {/*<div className="mb-4">*/}
                        {/*    <label className="form-label">Logo:</label>*/}
                        {/*    <input*/}
                        {/*        type="file"*/}
                        {/*        className="form-control"*/}
                        {/*        onChange={handleLogoChange}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div>
                            <button className="btn btn-primary" type="submit">
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Company;

